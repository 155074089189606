import {
	getBlockedWord,
	getByDepartment,
	getDepartment,
	getFaq,
	getUser,
} from 'api/config'
import { getRoleFunction } from 'api/roleFunction'
import { userMe } from './auth'

export const CONFIG = 'CONFIG',
	CONFIG_UPDATE = 'CONFIG_UPDATE',
	UPDATE_CONFIG_PARTIAL = 'UPDATE_CONFIG_PARTIAL'

export const updateConfigPartial = (type, data) => {
	return (dispatch, getState) => {
		const configState = getState().config

		if (type === 'department') {
			const newDepartments = configState.department.map(department => {
				if (department.id === data.id) {
					return data
				}

				return department
			})
			dispatch({
				type: UPDATE_CONFIG_PARTIAL,
				payload: { department: newDepartments },
			})
		}
	}
}

export const getConfigList = (
	update = ['blockedWord', 'department', 'faq', 'user', 'roleFunction']
) => {
	return (dispatch, getState) => {
		const fnc = async () => {
			let data = {}
			if (update.includes('blockedWord') && data) {
				const blockedWord = await getBlockedWord()
				data.blockedWord = blockedWord.data.list
			}

			if (update.includes('department')) {
				const department = await getDepartment()
				data.department = department.data
			}

			if (update.includes('departmentById')) {
				let id = getState().protocolSelect?.department_id
				const department = await getByDepartment(id)
				data.departmentById = department.data
			}

			if (update.includes('faq')) {
				const faq = await getFaq()
				data.faq = faq.data
			}
			if (update.includes('user')) {
				const userEmail = getState().auth.email
				const user = await getUser({ userEmail })
				data.user = user.data
				data.digitalkUsers = user.digitalkUsers
			}
			if (update.includes('roleFunction')) {
				const roleFunction = await getRoleFunction()
				data.roleFunction = roleFunction.data
			}
			dispatch({
				type:
					Object.keys(getState().config).length > 0 ? CONFIG_UPDATE : CONFIG,
				...data,
			})
		}

		fnc()
	}
}

export const updateConfigUser = data => {
	return (dispatch, getState) => {
		const fnc = async () => {
			const currMe = data.find(({ user_id }) => user_id === getState().auth.id)

			if (!currMe) {
				return
			}

			if (getState().auth?.status !== currMe?.status) {
				dispatch(userMe())
			}

			dispatch({
				type: CONFIG_UPDATE,
				user: getState().config?.user?.map(user => {
					const currUser = data.find(({ user_id }) => user_id === user.id)
					return currUser ? { ...user, ...currUser } : user
				}),
			})
		}
		if (Object.keys(getState().config).length > 0) {
			fnc()
		}
	}
}
